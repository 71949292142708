<template>
  <div>
    <banner-contact />
    <form-contact />
    <office-contact />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import bannerContact from './bannerContact.vue'
import formContact from './formContact.vue'
import officeContact from './officeContact.vue'

export default {
  components: {
    BRow,
    BCol,
    bannerContact,
    formContact,
    officeContact,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-contact.scss';
</style>
