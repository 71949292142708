<template>
    <div class="office-banner">
        <b-row class="m-0 p-0">
            <b-col
            class="office-left"
            md="6"
        >
            <b-img
                fluid
                :src="require('@/assets/images/contact/global.png')"
                alt="global-office-img"
            />
        </b-col>
        <b-col
            class="office-right"
            md="6"
        >
            <div class="banner-bg-right"></div>
            <h3>Connect with our global office</h3>
            <p>USA HQ</p>
            <span>200 E Pratt Street, Suite 4100</span>
            <br>
            <span>Baltimore, MD 21202</span>
            <br>
            <span>United States of America</span>
            <ul class="list-contact">
                <li>
                    <feather-icon
                        icon="PhoneCallIcon"
                    />
                    <span>infousa@costperform.com</span>
                </li>
                <li>
                    <feather-icon
                        icon="MessageSquareIcon"
                    />
                    <span>Support:+31 347 355 027</span>
                </li>
                <li>
                    <feather-icon
                        icon="MailIcon"
                    />
                    <span>infousa@costperform.com</span>
                </li>
            </ul>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import { BRow, BCol, BImg } from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BImg,
    },
}
</script>