<template>
    <div class="contact-form">
        <b-col
            offset-lg="2"
            lg="8"
        >
            <h2>Let’s get in touch!</h2>
            <p>We are ready to answer all your questions about cost and performance management</p>
            <b-form>
                <b-row>
                    <b-col
                    cols="12"
                    md="6"
                    >
                    <!-- Field: Name -->
                    <b-form-group
                        label="Name *"
                        label-for="contact-name"
                    >
                        <b-form-input
                        id="contact-name"
                        placeholder="John Doe"
                        />
                    </b-form-group>
                    </b-col>

                    <!-- Field: Email -->
                    <b-col
                    cols="12"
                    md="6"
                    >
                    <b-form-group
                        label="Email *"
                        label-for="contact-email"
                    >
                        <b-form-input
                        id="contact-email"
                        type="email"
                        placeholder="johndoe@gmail.com"
                        />
                    </b-form-group>
                    </b-col>

                    <b-col
                    cols="12"
                    md="6"
                    >
                    <b-form-group
                        label="Company *"
                        label-for="company"
                    >
                        <b-form-input
                        id="company"
                        placeholder="Company Name"
                        />
                    </b-form-group>
                    </b-col>

                    <!-- Field: Phone -->
                    <b-col
                    cols="12"
                    md="6"
                    >
                    <b-form-group
                        label="Phone Number"
                        label-for="contact-phone"
                    >
                        <b-form-input
                        id="contact-phone"
                        placeholder="000 000 000"
                        />
                    </b-form-group>
                    </b-col>

                    <b-col
                    cols="12"
                    md="12"
                    >
                    <b-form-group
                        label="Subject *"
                        label-for="subject"
                        >
                        <b-form-input
                            id="subject"
                        />
                    </b-form-group>
                    </b-col>

                    <b-col
                    cols="12"
                    md="12"
                    >
                    <b-form-group
                        label="Message *"
                        label-for="message"
                        >
                        <b-form-input
                            id="message"
                        />
                    </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        md="12"
                    >
                        <b-form-group>
                            <b-form-checkbox
                                id="register-privacy-policy"
                                v-model="status"
                                name="checkbox-1"
                            >
                                I allow Cost Ctrl to process my personal data according to the Privacy policy.*
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>

                    <b-col
                        class="btn-contactform"
                        cols="12"
                        md="12"
                    >
                        <b-button class="contactpage-btn">
                            Send message
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-col>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BButton, } from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BForm,
        BFormGroup, 
        BFormInput,
        BFormCheckbox,
        BButton,
    },
}
</script>